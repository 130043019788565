<template>
    <section id="workingArea">
        <div class="swapBtn_sm d-flex align-items-center">
            <router-link to="/skywalkers">
            Mint
            </router-link>
            <router-link to="/skywalkersView" class="btn2">
            View
            </router-link>
        </div>
        <div id="tabSec" class="align-items-center" style="max-width:600px;">
            <div class="balance" style="max-width:600px;">
                <div class="sol q-my-md">
                    <div class="align-items-center walletAddress">
                        <q-input class="address"
                            standout="bg-indigo-5 text-black" type="text" placeholder="Wallet Address"
                            v-model="address"
                            :bottom-slots="false" :hide-bottom-space="true">
                        </q-input>
                    </div>
                </div>
                <div class="walletBtn" @click="search">Submit</div>
            </div>
        </div>
        <div class = "row my-7 mx-0 text-white q-mt-lg" 
            v-if="this.$store.state.skywalkersnft.searchResult" 
            style="width:100%; padding-left:5px;">
            <div class="col-lg-3 text-white col-md-4 col-sm-12 flex justify-center q-mt-lg" 
                v-for="(imageURI,idx) in this.$store.state.skywalkersnft.imageURIs"
                :key="idx">
                <div class="nft_card1">
                    <div class="nft_card_image" v-lazy-container="{ selector: 'img' }">
                        <img
                            :data-src="imageURI"
                            :data-loading="loadimage"
                            contain
                        />
                    </div>
                    <div class="sub_panel text-justify text-center q-mt-sm">
                        <div class = "tokenNames" style="font-weight: 600; color: rgb(194 86 207);">{{showShortName(idx)}}</div>
                        <div class = "tokenRarity" style="font-weight: 600;">Rarity Rank: {{tokenAttributes(idx).value}}</div>
                        <div class="walletBtn_sm" style="border-radius: 5px; color: #9b8396;" @click="showModal(idx)">Send</div>
                    </div>
                </div>              
           </div>
        </div>
        <div v-if="showModalSend" class="modal">
            <div class="modal__content_view" v-click-outside="onClickOutside">
                <div class="model__content__item">
                    <div>Send Token</div>
                        <q-btn icon="close" flat round dense
                            @click="showModalSend = !showModalSend" color="white">
                    </q-btn>
                </div>
                <div class="model__content__item address">
                    <q-input standout="bg-indigo-5 text-white"  
                        type="text" v-model="recipientAddress" placeholder="Address"
                        :bottom-slots="false" :hide-bottom-space="true" class="search">
                        </q-input>
                </div>
                <div class="model__content__item">
                    <div id="claim"
                        @click="send" class="walletBtn q-mt-sm">Send</div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import { BigNumber } from 'bignumber.js'
    import vClickOutside from 'v-click-outside'

    export default {
        components: {
        },
        data() {
            return {
                loadimage: require("@/assets/loading2.gif"),
                pending: false,
                error: false,
                address: null,
                showModalSend: false,
                recipientAddress: null,
                tokenId: null,
                transfered: false
            }
        },
        mounted() {
        },
        watch:{
            showModalSend:function(newValue){
                if(!newValue){
                    this.research();
                    this.transfered = false;
                }
            }
        },
        computed: {   
            isMetaMaskConnected() {
                return this.$store.state.account != null;
            },   
        },
        methods: {
            connectWallet() {
                this.$store.dispatch('connect');
            },
            onClickOutside (event) {
                this.showModalSend = false;
            },
            showModal(index) {
                this.showModalSend = true;
                this.tokenId = this.$store.state.skywalkersnft.tokenIds[index];
            },
            async search() {
                this.pending = true;
                try {
                    let result;
                    result = await this.$store.dispatch('getTokenIdsOfOwnerInSkywalkers', {
                        account:this.address
                    })

                    if (!result) {
                        this.pending = false;
                        this.error = true;
                        return;
                    }

                    await this.$store.dispatch('constructTokenInfoInSkywalkers')

                    this.error = false;
                }
                catch (e) {
                    this.error = e;
                }
                this.pending = false;
            },
            showShortName(index) {
                let name = this.$store.state.skywalkersnft.tokenNames[index];
                if (name.length < 20)
                    return name
                else
                    return (
                        '"' + name.substring(0, 10) +
                        '...' +
                        name.substring(name.length - 8, name.length) + '"'
                    );
            },
            tokenPrice(index) {
                return BigNumber(this.$store.state.skywalkersnft.tokenPrices[index]).shiftedBy(-18);
            },
            tokenAttributes(index) {
                return this.$store.state.skywalkersnft.tokenAttributes[index][0];
            },
            async research() {
                if (this.transfered)
                {
                    try {
                        await this.$store.dispatch('constructTokenInfoInSkywalkers')
                    }
                    catch (e) {
                        this.error = e;
                    }
                }
            },
            send() {
                this.$store.dispatch('transferTokenInSkywalkers', 
                    {
                        from:this.address, 
                        to:this.recipientAddress, 
                        tokenID:this.tokenId
                    }
                )
                this.transfered = true;
            },
        }
    }
</script>
<style>

.nft_card {
    width: 3000px; 
    min-height: 350px;
    padding:8px;
    box-shadow: 0 1px 3px 1px #A577E1;
    border-radius:10px;
    background-color: #1C2C62;
    margin-bottom: 20px;
}

.nft_card_image {
    text-align:center;
    width:100%;
    height:220px;
}

.nft_card img {
    width: 95%; 
    height: 250px;
    border-radius: 20px;
    background-color: #1B285F;
}

.nft_card1 {
    width: 250px; 
    min-height: 250px;
    padding:8px;
    box-shadow: 0 1px 3px 1px #A577E1;
    border-radius:10px;
    background-color: #1C2C62;
    margin-bottom: 20px;
}

.nft_card1 img {
    width: 95%; 
    border-radius: 20px;
    background-color: #1B285F;
}

.nft_card_sm {
    width: 140px; 
    height: 140px;
    padding:5px;
    box-shadow: 0 0px 0px 1px #204f64;
    border-radius:5px;
    background-color: #1C2C62;
}
</style>
